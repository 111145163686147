import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { TEXTS } from '../landing/landing.constants';

const text = 'We have some exciting updates coming. Please check back soon';

export default function MaintenancePage() {
  return (
    <section className="landing-section">
      <Container>
        <Row className="d-flex align-items-center justify-content-center">
            <Row>
              <img src={TEXTS.LOGO_URL} style={{ width: '150px', height: 'auto' }} alt={TEXTS.TITLE} />
            </Row>
            <h1 className="mb-4 mt-1">{TEXTS.TITLE}</h1>
            <p className="text-lg">{text}</p>
        </Row>
      </Container>
    </section>
  );
}
